import React from 'react';
import { useTranslation } from 'react-i18next';

import { Group, Row, CheckboxInput } from '@common/components/form';

export const ProfileNotificationSettings = () => {
  const { t } = useTranslation();

  return (
    <div className="ProfilePreferencesSettings">
      <Row>
        <h1>{t('core:profile_form_tab_notifications')}</h1>
      </Row>

      <Row>
        <Group>
          <CheckboxInput
            name="notifications.enabled_flexchange_notifications"
            label={t('core:profile_form_notifications_flexchange')}
            description={t('core:profile_form_notifications_flexchange_description')}
          />
        </Group>
      </Row>

      <Row>
        <Group>
          <CheckboxInput
            name="notifications.enabled_weekly_update"
            label={t('core:profile_form_notifications_weekly_update')}
            description={t('core:profile_form_notifications_weekly_update_description')}
          />
        </Group>
      </Row>

      <Row>
        <Group>
          <CheckboxInput
            name="notifications.enabled_access_request_emails"
            label={t('core:profile_form_notifications_access_requests')}
            description={t('core:profile_form_notifications_access_requests_description')}
          />
        </Group>
      </Row>
    </div>
  );
};
