import React, { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import Card from '@common/components/card';
import { Icon } from '@common/components/icon';
import Dropdown from '@common/components/dropdown';
import { ConfirmButton } from '@common/components/confirm-button';
import Permission from '@common/components/permission';
import { EPermissions } from '@common/definitions';
import AdminForm from '@modules/admin/forms/admin';
import { fetchRoles, fetchPermissionGroups } from '../../../../../organisation/actions';
import * as organisationSelectors from '../../../../../organisation/selectors/organisation';
import { mergeNamesToString } from '@utils/merged-names-text';
import { useThunkDispatch, useAppSelector } from '@common/hooks';
import { removeUserFromCommunity, removeUserFromFunctionGroup } from '../../../../actions';
import { AddUserToCommunity } from '../../../../forms/add-user-to-community';
import { AddUserToFunctionGroup } from '../../../../forms/add-user-to-function-group';

import { ExtendedUser, OrganisationFunction, OrganisationScope, NetworkScope } from '@common/types/objects';
import Tooltip from '@common/components/tooltip';
import { updateUserRoles } from '@modules/network/actions';

type MembershipTypes = {
  type: 'function';
  membership: OrganisationFunction;
} | {
  type: 'network';
  membership: NetworkScope;
} | {
  type: 'organisation';
  membership: OrganisationScope;
};

type MembershipRowDropdownProps = MembershipTypes & {
  user: ExtendedUser;
  deleteDisabled?: boolean;
  updateAdmin: (...args: any[]) => any;
};

const SyncedIcon = memo(() => {
  const { t } = useTranslation();
  return (
    <Tooltip placement="left" title={t('core:synced_by_the_integration')}>
      <Icon type="sync_alt" className="syncIcon" color="orange" />
    </Tooltip>
  );
});

export const MembershipRowDropdown = ({
  user,
  membership,
  deleteDisabled = false,
  updateAdmin,
  ...props
}: MembershipRowDropdownProps) => {

  const { t } = useTranslation();
  const params = useParams<{ networkId?: string, userId: string }>();
  const roles = useAppSelector(organisationSelectors.roles);
  const thunkDispatch = useThunkDispatch();
  const [isEditRolesModalVisible, setIsEditRolesModalVisible] = useState(false);

  const handleRemove = () => {
    if (props.type === 'network') {
      return thunkDispatch(removeUserFromCommunity(user.id, membership.id));
    }

    if (props.type === 'function') {
      return thunkDispatch(removeUserFromFunctionGroup(user.id, membership.id, params.networkId));
    }
  };

  return (
    <>
      <Dropdown
        className="MembershipRowDropdown"
        alignRight
        trigger={<Icon type="more_vert" />}
      >
        {props.type !== 'function' && (
          <Dropdown.Item icon="local_police" onClick={() => setIsEditRolesModalVisible(true)}>
            {t('core:profile_edit_membership', { context: props.type })}
          </Dropdown.Item>
        )}
        {props.type !== 'organisation' && !deleteDisabled && (
          <ConfirmButton
            title={t('core:remove_from_community_confirmation', { community: membership.name })}
            onConfirm={handleRemove}
          >
            <Dropdown.Item icon="delete__filled" danger>
              {t('core:profile_remove_membership', { context: props.type })}
            </Dropdown.Item>
          </ConfirmButton>
        )}
      </Dropdown>

      {isEditRolesModalVisible && (
        <AdminForm
          // @ts-expect-error
          show
          network={props.type === 'network' ? membership : undefined}
          updateAdmin={updateAdmin}
          // @ts-expect-error
          admin={{ ...user, roles: membership.roles || [] }}
          onClose={() => setIsEditRolesModalVisible(false)}
          roles={props.type === 'network' ? roles.network : roles.organisation}
          fetchGroups={(level: number) => thunkDispatch(fetchPermissionGroups(level))}
        />
      )}
    </>
  );
};

type ProfileMembershipsProps = {
  user: ExtendedUser;
};

export const ProfileMemberships = ({ user }: ProfileMembershipsProps) => {
  const { t } = useTranslation();
  const thunkDispatch = useThunkDispatch();

  useEffect(() => {
    thunkDispatch(fetchRoles());
  }, []);

  return (
    <Card containerClassName="ProfileMemberships">
      <Card.Content>
        <div className="ProfileMemberships__Group">
          <b>{t('core:organization')}</b>
          <div className="ProfileMemberships__Row">
            <Icon type="business__filled" />
            <span className="ProfileMemberships__Name">{user.scopes.organisation.name}</span>

            <Permission
              name={[
                EPermissions.ORGANISATION_USERS_UPDATE,
                EPermissions.NETWORK_USERS_UPDATE
              ]}
            >
              {/* @ts-expect-error */}
              <MembershipRowDropdown
                type="organisation"
                membership={user.scopes.organisation}
                user={user}
              />
            </Permission>
          </div>
        </div>

        <div className="ProfileMemberships__Group">
          <b>{t('core:communities')}</b>
          { /*
            do not rely on user.scopes.networks[].memberships[], see comments
            on PD-8672
          */ }
          {user.scopes.networks.map((network: any) => {
            const isSynced = !!network?.added_by_integration;
            return (
              <div
                className={
                  `ProfileMemberships__Row${isSynced ? ' synced' : ''}`
                }
                key={network.id}
              >
                <Icon type="public__filled" />
                <div className="ProfileMemberships__Labels">
                  <span className="ProfileMemberships__Name">{network.name}</span>
                  <span className="ProfileMemberships__Roles">
                    {mergeNamesToString(network.roles, 2, 'name')}
                  </span>
                </div>

                <Permission
                  name={[
                    EPermissions.ORGANISATION_USERS_UPDATE,
                    EPermissions.NETWORK_USERS_UPDATE
                  ]}
                >
                  { isSynced && <SyncedIcon /> }
                  <MembershipRowDropdown
                    type="network"
                    membership={network}
                    user={user}
                    deleteDisabled={isSynced}
                    updateAdmin={updateUserRoles}
                  />
                </Permission>
              </div>
            );
          })}

          <Permission
            name={[
              EPermissions.ORGANISATION_USERS_UPDATE,
              EPermissions.NETWORK_USERS_UPDATE
            ]}
          >
            <AddUserToCommunity user={user} />
          </Permission>
        </div>

        <div className="ProfileMemberships__Group">
          <b>{t('core:function_groups')}</b>
          {user.scopes.functions.map((orgFunction: any) => {
            const isSynced = !!orgFunction?.added_by_integration;
            return (
              <div
                className={
                  `ProfileMemberships__Row${isSynced ? ' synced' : ''}`
                }
                key={orgFunction.id}
              >
                <Icon type="assignment_ind__filled" />
                <span className="ProfileMemberships__Name">{orgFunction.name}</span>

                <Permission
                  name={[
                    EPermissions.ORGANISATION_USERS_UPDATE,
                    EPermissions.NETWORK_USERS_UPDATE
                  ]}
                >
                  { isSynced && <SyncedIcon /> }
                  {/* @ts-expect-error */}
                  <MembershipRowDropdown
                    type="function"
                    membership={orgFunction}
                    user={user}
                    deleteDisabled={isSynced}
                  />
                </Permission>
              </div>
            );
          })}

          <Permission
            name={[
              EPermissions.ORGANISATION_USERS_UPDATE,
              EPermissions.NETWORK_USERS_UPDATE
            ]}
          >
            <AddUserToFunctionGroup user={user} />
          </Permission>
        </div>
      </Card.Content>
    </Card>
  );
};
