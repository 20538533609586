import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { Icon } from '@common/components/icon';
import Tooltip from '@common/components/tooltip';
import { useThunkDispatch } from '@hooks/redux';
import { refreshRedeemCode } from '../../actions';
import { AlertService } from '@services/alert';
import { ExtendedUser } from '@common/types/objects';

type IntegrationRedeemCodeProps = {
  user: ExtendedUser;
};

export const IntegrationRedeemCode = ({ user }: IntegrationRedeemCodeProps) => {
  const { t } = useTranslation();
  const NO_CODE = t('core:profile_app_no_redeem_code');
  const thunkDispatch = useThunkDispatch();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [activeCode, setActiveCode] = useState(user?.scopes?.organisation.redeem_code || NO_CODE);

  useEffect(() => {
    setActiveCode(user?.scopes?.organisation.redeem_code || NO_CODE);
  }, [user?.scopes?.organisation.redeem_code]);

  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);

      await thunkDispatch(refreshRedeemCode(user.id));

      AlertService.success(t('core:alert_profile_refreshed_redeem_code'));
    } catch (err) {
      setActiveCode(NO_CODE);
      AlertService.error(t('core:alert_profile_could_not_refresh_redeem_code'));
    }

    setIsRefreshing(false);
  };

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(activeCode!);

      AlertService.success(t('core:copied_to_clipboard'));
    } catch (err) {
      AlertService.error(t('common:clipboard_unsupported'));
    }
  };

  return (
    <div className="IntegrationRedeemCode">
      <b>
        {t('core:profile_app_redeem_code')}
        <Icon type="info__filled" />
      </b>

      <div className="IntegrationRedeemCode__CodeBox">
        {!isRefreshing ?
          <span className={activeCode === NO_CODE ? 'IntegrationRedeemCode__CodeBox--error' : ''}>{activeCode}</span> :
          t('core:user_detail_redeem_code_refreshing')}
        {
          activeCode && activeCode !== NO_CODE &&
            <Tooltip title={t('core:copy_to_clipboard')}>
              <Icon type="content_copy" onClick={handleCopyToClipboard} />
            </Tooltip>
        }
        <Tooltip title={t('core:refresh_redeem_code')}>
          <Icon type="sync" onClick={!isRefreshing ? handleRefresh : undefined} />
        </Tooltip>
      </div>

      <Trans
        i18nKey="core:profile_app_redeem_code_explanation"
        components={[<a />]}
      />
    </div>
  );
};
