import React, {
  useState,
  cloneElement,
  ReactElement,
} from 'react';
import { useTranslation } from 'react-i18next';

import AsyncList from '@common/components/list/async';
import { Modal } from '@common/components/modal';
import { Icon } from '@common/components/icon';
import { Button } from '@common/components/button';
import { DocumentIcon } from '../document-icon';
import { FolderModalForm } from '../../forms/folder-modal-form';
import { useToggle, useAppSelector, useThunkDispatch } from '@hooks/index';
import { combineClassNames } from '@utils/combineClassNames';
import { fetchDocuments } from '../../actions';
import { resolveFolderKey } from '../../utils';
import { getDocuments, getNextCursor, getRawDocumentItems } from '../../selectors';

import type { Document, Folder } from '../../types';

type MoveDocumentListRowProps = {
  item: Document;
  navigationHandler: (folderKey: string) => void;
};

export const PickFolderListRow = ({ item, navigationHandler }: MoveDocumentListRowProps) => {
  const fullClassName = combineClassNames('PickFolderModal__Row', {
    'PickFolderModal__Row--disabled': !item.is_folder,
  });

  return (
    <div role="option" className={fullClassName} onClick={item.is_folder ? () => navigationHandler(item.id) : undefined}>
      <div className="PickFolderModal__DocumentIcon">
        <DocumentIcon item={item} />
      </div>
      <div>
        <div className="PickFolderModal__Title">
          <a>{item.name}</a>
          {item.is_favorited && <Icon type="star__filled" />}
        </div>
      </div>
      {item.is_folder && <Icon type="chevron_right" />}
    </div>
  );
};

type OnPickProps = {
  selectedFolder: Folder | undefined;
  handleClose: () => void;
};

export type PickFolderModalProps = {
  children?: ReactElement;
  initialIsVisible?: boolean;
  onPick: (props: OnPickProps) => void | Promise<void>;
  acceptButtonLabel?: string;
  onClose?: () => void;
};

export const PickFolderModal = ({
  children,
  initialIsVisible = false,
  onPick,
  acceptButtonLabel,
  onClose,
}: PickFolderModalProps) => {
  const { t } = useTranslation();
  const thunkDispatch = useThunkDispatch();

  const [isVisible, toggleIsVisible] = useToggle(initialIsVisible);
  const [currentFolderId, setCurrentFolderId] = useState<string>();

  const folderKey = resolveFolderKey({ folderId: currentFolderId });
  const rawDocuments = useAppSelector((state) => getRawDocumentItems(state));
  const documents = useAppSelector((state) => getDocuments(state, folderKey, false));
  const nextCursor = useAppSelector((state) => getNextCursor(state, folderKey));

  const currentDocument = currentFolderId ? rawDocuments[currentFolderId] : undefined;
  const currentFolder = currentDocument?.is_folder ? currentDocument : undefined;

  const handleNavigateBack = () => setCurrentFolderId(currentFolder?.parent_folder_id || undefined);
  const handleClose = () => {
    toggleIsVisible();
    onClose?.();
  };

  const handlePick = () => onPick?.({
    handleClose,
    selectedFolder: currentFolder,
  });

  return (
    <Modal
      className="PickFolderModal"
      show={isVisible}
      header={(
        <div className="PickFolderModal__Header">
          {currentFolder && <Icon type="chevron_left" role="button" onClick={handleNavigateBack} />}
          <span>{currentFolder?.name || t('documents:filter_all')}</span>
        </div>
      )}
      onClose={handleClose}
      footerNote={(
        <FolderModalForm currentFolder={currentFolder}>
          <Button iconRight="add" className="pull-left">
            {t('documents:create_folder')}
          </Button>
        </FolderModalForm>
      )}
      footer={(
        <Button type="primary" onClick={handlePick}>{acceptButtonLabel || t('common:move')}</Button>
      )}
      content={(
        <AsyncList
          key={currentFolderId}
          items={documents}
          disableInitialFetch={documents?.length > 0}
          placeholder={(
            <span className="PickFolderModal__Placeholder">{t('documents:empty_folder')}</span>
          )}
          data={{
            useCursor: true,
            // @ts-expect-error
            onFetch: (...args) => thunkDispatch(fetchDocuments(...args)),
            filter: {
              folderId: currentFolderId,
            },
            cache: {
              nextCursor,
              pagination: {
                offset: documents.length,
              },
            },
          }}
          renderRow={PickFolderListRow}
          rowProps={{
            navigationHandler: setCurrentFolderId,
          }}
        />
      )}
    >
      {children && cloneElement(children, { onClick: toggleIsVisible })}
    </Modal>
  );
};
