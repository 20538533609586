import * as R from 'ramda';

import type { TypeOfItemFromArray } from '@common/types/util-types';

export const unwrapRecursiveKey = <T extends Record<string, any>, K extends string>(
  object: T,
  key: K,
): Omit<T, K>[] => {
  if (!object?.[key]) return [object];

  const currWithout = R.omit([key], object);
  return [currWithout, ...unwrapRecursiveKey(object[key], key)];
};

export const unwrapRecursiveKeysInArray = <T extends Record<string, any>[], K extends string>(
  array: T,
  key: K,
): Omit<NonNullable<TypeOfItemFromArray<T>[K]>, K>[] => (
    array.flatMap((element) => (
      element[key] ? unwrapRecursiveKey(element[key], key) : []
    ))
  );

type ObjectValue<T> = T extends Record<string, infer U> ? U : never;

export const findRelatedObjects = <LM extends Record<string, any>, LK extends string>(
  parentObject: Record<string, any>,
  lookupMap: LM,
  lookupKey: LK,
): ObjectValue<LM>[] | undefined => {
  const nextObject = lookupMap?.[parentObject[lookupKey]];
  if (!nextObject) return undefined;

  const allObjects = [nextObject];

  if (nextObject?.[lookupKey]) {
    allObjects.push(...findRelatedObjects(nextObject, lookupMap, lookupKey) || []);
  }

  return allObjects;
};
