import * as React from 'react';
import Icon from '../../../../common/components/icon';

const ModuleIcon = ({ module }) => {
  switch (module.type) {
    case 'quiz':
      return (
        <div className="AcademyModuleIcon AcademyModuleIcon--color-purple">
          <Icon type="extension" />
        </div>
      );
    default:
      return (
        <div className="AcademyModuleIcon AcademyModuleIcon--color-blue">
          <Icon type="sort" />
        </div>
      );
  }
};

export default ModuleIcon;
