import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Redirect,
  useRouteMatch,
  useHistory,
  Route,
  generatePath,
} from 'react-router';

import { TopNavigationBar } from '@common/components/navigation-bar';
import { useAppSelector, useThunkDispatch, usePermission } from '@common/hooks';
import { Spinner } from '@common/components/spinner';
import { Button } from '@common/components/button';
import { Switch } from '@common/components/router';
import { ConfirmButton } from '@common/components/confirm-button';
import Dropdown from '@common/components/dropdown';
import Container from '@common/components/container';
import Permission from '@common/components/permission';
import { EComponentTypes, EPermissions, EPlanPackageConfig } from '@common/definitions';
import { AlertService } from '@services/alert';
import { AboutProfile } from './about-profile';
import { PersonalDocuments } from './personal-documents';
import { ProfileForm } from '../../forms/profile';
import fetchUser from '../../actions/fetch-user';
import postConversation from '../../../chat/actions/post-conversation';
import { deleteUser } from '../../../admin/actions';
import { UserProfileTypes } from '../../definitions';
import * as usersSelector from '../../selectors/users';
import * as loggedUserSelector from '../../selectors/logged-user';
import UserProfileForms from './user-profile-forms';
import UserProfileSurveys from './user-profile-surveys';
import UserProfileELearning from './user-profile-elearning';
import UserProfileOnboarding from './user-profile-onboarding';
import { useSelector } from 'react-redux';
import * as orgSelectors from '@modules/organisation/selectors/organisation';
import * as networkSelectors from '@modules/network/selectors/network';
import fetchNetworkUser from '@modules/core/actions/fetch-network-user';
import deleteNetworkUser from '@modules/network/actions/delete-user';
import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';

import '../../../survey/containers/responses/responses.scss';

type ProfileContainerProps = {
  userId: string | undefined;
  type: UserProfileTypes;
  fetchDetails?: boolean;
};

const ProfileContainer = ({ userId, type, fetchDetails }: ProfileContainerProps) => {
  const { t } = useTranslation();
  const thunkDispatch = useThunkDispatch();
  const history = useHistory();
  const { path, url, params } = useRouteMatch();
  const loggedUser = useAppSelector(loggedUserSelector.selected);
  const user = useAppSelector((state) => {
    if (userId) {
      const data = usersSelector.byId(state, userId);
      if (loggedUser.id === userId) {
        return {
          ...data,
          ...loggedUser,
        };
      }
      return data;
    }
    return undefined;
  });
  const selectedNetwork = useAppSelector((store) => store.network.selected);
  // @ts-expect-error
  const organisation = useSelector((state) => orgSelectors.selected(state));
  const network = useSelector((state) => networkSelectors.selected(state));
  const enabledComponents = [
    ...(organisation?.enabled_components || []),
    ...(network?.enabled_components || [])
  ];

  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const effect = async () => {
      setIsFetching(true);

      const inAdminSide = url.startsWith('/admin');

      if (userId === loggedUser.id && !url.includes('/profile')) {
        const profilePath = inAdminSide ? '/admin/profile/about' : '/profile/about';
        return history.replace(profilePath);
      }

      if (fetchDetails || !user) {
        const action = inAdminSide ?
          fetchUser(userId) :
          fetchNetworkUser(network.id, userId || '');
        await thunkDispatch(action);
      }

      setIsFetching(false);
    };
    effect();
  }, [userId, organisation, setIsFetching, network, thunkDispatch]);

  const handleOpenConversation = () => {
    if (!user) return;

    history.push(`${path.includes('admin') ? '/admin' : ''}/conversations`);
    thunkDispatch(postConversation({
      type: 'private',
      participant_ids: [user.id],
    }));
  };

  const handleRemoveUser = async () => {
    if (!user) return;

    try {
      const inAdminSide = url.startsWith('/admin');
      const action = inAdminSide ? deleteUser(user.id) : deleteNetworkUser(user.id);
      await thunkDispatch(action);

      AlertService.success(t('core:removed_user', { context: 'success' }));
      history.push(inAdminSide ? '/admin/users' : `/networks/${selectedNetwork}/users`);
    } catch (e: any) {
      if (e && typeof e === 'object' && 'status_code' in e) {
        AlertService.forStatus(e.status_code, {
          warning: t('core:removed_user', { context: 'warning' }),
          error: t('core:removed_user', { context: 'error' }),
        });
      }
    }
  };

  const {
    isAvailable: canAccessPersonalDocuments, showUpgradeModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.DOCUMENTS_USER_SPECIFIC);

  const canViewDocuments = usePermission([
    EPermissions.ORGANISATION_PERSONAL_DOCUMENTS_VIEW_ALL,
    EPermissions.ORGANISATION_PERSONAL_DOCUMENTS_CREATE
  ]) && enabledComponents.includes(EComponentTypes.DOCUMENTS);

  const canViewOnboardingStats = usePermission([
    EPermissions.ORGANISATION_ONBOARDING_STATISTICS,
    EPermissions.ORGANISATION_ONBOARDING_COURSES_STATISTICS,
    EPermissions.NETWORK_ONBOARDING_STATISTICS
  ]) && enabledComponents.includes(EComponentTypes.ONBOARDING);

  const canViewELearningStats = usePermission([
    EPermissions.ORGANISATION_ACADEMY_COURSES_VIEW,
    EPermissions.NETWORK_ELEARNING_STATISTICS
  ]) && enabledComponents.includes(EComponentTypes.ACADEMY);

  const canViewSurveyStats = usePermission([
    EPermissions.ORGANISATION_SURVEYS_VIEW
  ]) && (
    enabledComponents.includes(EComponentTypes.SURVEYS) ||
    enabledComponents.includes(EComponentTypes.SURVEYS_PREMIUM)
  );

  const canViewFormStats = usePermission([
    EPermissions.ORGANISATION_FORMS_VIEW_ALL,
    EPermissions.ORGANISATION_CREATOR_FORMS_VIEW
  ]) && (
    enabledComponents.includes(EComponentTypes.FORMS) ||
    enabledComponents.includes(EComponentTypes.FORMS_PREMIUM)
  );

  const tabs = useMemo(() => {
    const slots = [
      {
        name: t('core:profile_tabs_label_about'),
        to: generatePath(`${path}/about`, params)
      },
      canViewDocuments ?
        {
          name: t('core:profile_tabs_label_documents'),
          to: generatePath(`${path}/personal-documents`, params),
          onClick: (e: any) => {
            if (!canAccessPersonalDocuments) {
              e.preventDefault();
              showUpgradeModal();
            }
          },
        } :
        undefined,
      canViewOnboardingStats ?
        {
          name: t('core:tab_onboarding'),
          to: generatePath(`${path}/onboarding`, params)
        } :
        undefined,
      canViewELearningStats ?
        {
          name: t('core:tab_eLearning'),
          to: generatePath(`${path}/elearning`, params)
        } :
        undefined,
      canViewSurveyStats ?
        {
          name: t('core:tab_surveys'),
          to: generatePath(`${path}/surveys`, params)
        } :
        undefined,
      canViewFormStats ?
        {
          name: t('core:tab_forms'),
          to: generatePath(`${path}/forms`, params)
        } :
        undefined
    ].filter((tab) => !!tab);

    if (slots.length <= 1) {
      // we would only have the "About" tab, no point in rendering only one tab
      return undefined;
    }
    return slots;
  }, [
    t, canViewDocuments, canViewELearningStats, canViewOnboardingStats,
    canViewSurveyStats, canViewFormStats
  ]);

  if (!user || isFetching) {
    return (
      <div className="AsyncSpinner">
        <Spinner />
      </div>
    );
  }

  const onEdit = () => history.push(`${url}/about/edit`);

  return (
    <Container name="ProfileSettings">
      <TopNavigationBar
        image={user?.profile_img || ''}
        title={user?.full_name}
        tabs={tabs}
        action={(
          <>
            {type !== UserProfileTypes.OWN && (
              <Button icon="chat__filled" onClick={handleOpenConversation}>
                { t('core:send_message') }
              </Button>
            )}
            <Permission
              name={[
                EPermissions.ORGANISATION_USERS_REMOVE,
                EPermissions.ORGANISATION_USERS_UPDATE,
                EPermissions.NETWORK_USERS_REMOVE,
                EPermissions.NETWORK_USERS_UPDATE,
              ]}
            >
              <Dropdown alignRight trigger={<Button icon="more_vert" />}>
                <Permission
                  name={[
                    EPermissions.ORGANISATION_USERS_UPDATE,
                    EPermissions.NETWORK_USERS_UPDATE
                  ]}
                >
                  <Dropdown.Item icon="settings" onClick={onEdit}>
                    {t('core:edit_profile_details')}
                  </Dropdown.Item>
                </Permission>

                <Permission
                  name={EPermissions.ORGANISATION_USERS_REMOVE}
                  test={type !== UserProfileTypes.OWN}
                >
                  <ConfirmButton
                    title={t('core:assert_remove_user_from_org_title')}
                    description={t('core:assert_remove_user_from_org_desc')}
                    confirmText={t('common:remove')}
                    onConfirm={handleRemoveUser}
                  >
                    <Dropdown.Item icon="delete__filled" danger>
                      {t('core:delete_user')}
                    </Dropdown.Item>
                  </ConfirmButton>
                </Permission>
              </Dropdown>
            </Permission>
          </>
        )}
      />

      {/* Allows to redirect straight to the form or a specific tab inside the form */}
      <Route path={[`${path}/about/edit/:tab`, `${path}/about/edit`]}>
        <ProfileForm
          type={type}
          user={user}
          onClose={() => history.replace(`${url}/about`)}
        />
      </Route>

      <Switch>
        <Route exact path={path} render={() => <Redirect to={`${url}/about`} />} />
        <Route path={`${path}/about`}>
          <AboutProfile user={user} onEdit={onEdit} />
        </Route>
        {canViewDocuments && (
          <Route
            path={[`${path}/personal-documents/:folderId`, `${path}/personal-documents`]}
            render={(props) => (
              <PersonalDocuments
                {...props}
                profilePath={generatePath(path, params)}
                user={user}
              />
            )}
          />
        )}
        {
          canViewOnboardingStats && (
            <Route path={`${path}/onboarding`}>
              <UserProfileOnboarding user={user} />
            </Route>
          )
        }
        {
          canViewELearningStats && (
            <Route path={`${path}/elearning`}>
              <UserProfileELearning user={user} />
            </Route>
          )
        }
        {
          canViewFormStats && (
            <Route path={`${path}/forms`}>
              <UserProfileForms user={user} />
            </Route>
          )
        }
        {
          canViewSurveyStats && (
            <Route path={`${path}/surveys`}>
              <UserProfileSurveys user={user} />
            </Route>
          )
        }
      </Switch>
    </Container>
  );
};

export default ProfileContainer;
