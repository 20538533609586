import { useState, useMemo, Dispatch, SetStateAction } from 'react';
import type { TypeOfValueFromRecord } from '@common/types/util-types';

export const useStateWithIdMapping = <T extends Record<string, any>>(
  mapping: T,
  initialId?: string,
): [TypeOfValueFromRecord<T> | undefined, Dispatch<SetStateAction<string | undefined>>] => {
  const [id, setId] = useState<string | undefined>(initialId);

  const item = useMemo<TypeOfValueFromRecord<T> | undefined>(() => {
    if (id) {
      return mapping?.[id];
    }
  }, [id, mapping]);

  return [item, setId];
};
