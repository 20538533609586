import React, {
  memo, useCallback, useEffect, useMemo, useState
} from 'react';
import Bar from '@common/components/bar';
import Container from '@common/components/container';
import Card from '@common/components/card';
import Table from '@common/components/table';
import type { ExtendedUser, LoggedUser } from '@common/types/objects';
import { useTranslation } from 'react-i18next';
import { Api } from '@common/services/api';
import { selected } from '@modules/organisation/selectors/organisation';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { SubmissionStatus } from '@modules/forms/components/submission-status';
import { Button } from '@common/components/button';
import Placeholder from '@common/components/placeholder/placeholder';
import ModalWindow from '@common/components/modal/modal-window';
import { ResponsesModalContent } from './user-profile-surveys';
import { formatSubmission } from '@modules/forms/selectors';

const formsTableRow = ({ item }: any) => {
  const form = item?.form;
  return [
    form?.title,
    item?.created_at ? moment(item.created_at).format('MMM D, YYYY HH:mm') : '-',
    item?.response_status ?
      <SubmissionStatus status={item?.response_status} /> :
      null
  ];
};

const FormResponses = memo(({ item, onHide }: any) => {
  const [responses, setResponses] = useState<any>(null);
  // console.log("debug FormResponses item", item);
  const submissionId = item?.id;
  const formId = item?.form?.id;
  const orgId = item?.organisation_id;
  useEffect(() => {
    const surveyUrl = `/v1/organisations/${orgId}/forms/${formId}`;
    const responseUrl = `${surveyUrl}/submissions/${submissionId}`;
    Api.get(responseUrl).then((result: any) => {
      setResponses(result);
    });
  }, [orgId, submissionId, formId, setResponses]);

  const submission = useMemo(() => {
    return formatSubmission(responses?.data, responses?.data);
  }, [responses]);

  return (
    <ResponsesModalContent
      title={item?.form?.title}
      onHide={onHide}
      submission={submission}
    />
  );
});

const ViewFormResponsesAction = memo(({ item }: any) => {
  // console.log("debug ViewSurveyResponsesAction props", props);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const onHide = useCallback(() => setShow(false), [setShow]);
  const onShow = useCallback(() => setShow(true), [setShow]);
  return (
    <ModalWindow
      trigger={<Button type="soft">{t('forms:view_response')}</Button>}
      show={show}
      onHide={onHide}
      onShow={onShow}
    >
      <FormResponses onHide={onHide} item={item} />
    </ModalWindow>
  );
});

type FormsTableProps = {
  organisationId: string;
  userId: string;
};

const FormsTable = memo(({ organisationId, userId }: FormsTableProps) => {
  const { t } = useTranslation();

  const onFetch = useCallback(async (nextCursor: string | null) => {
    let url = `/v1/organisations/${organisationId}/users/${userId}`;
    url += `/forms/submissions?limit=20&cursor=${nextCursor || 'true'}`;
    return Api.get(url);
  }, [organisationId, userId]);

  const columns = useMemo(() => {
    return [{
      label: t('forms:submission_header_name')
    }, {
      size: 180,
      label: t('forms:submission_header_submitted_at')
    }, {
      label: t('forms:submission_header_status')
    }];
  }, [t]);

  return (
    // @ts-expect-error
    <Table
      columns={columns}
      data={{
        useCursor: true,
        onFetch
      }}
      renderRow={formsTableRow}
      ActionComponent={ViewFormResponsesAction}
      placeholder={<Placeholder title={t('forms:no_forms_submitted_yet')} />}
    />
  );
});

type UserProfileFormsProps = {
  user: ExtendedUser | LoggedUser;
};

const UserProfileForms = memo(({ user }: UserProfileFormsProps) => {
  const { t } = useTranslation();

  const org = useSelector(selected);

  return (
    <Container.Content>
      <Bar>
        <h2>{ t('core:user_profile_forms_header') }</h2>
      </Bar>
      <Container.Column>
        <Card>
          <FormsTable userId={user.id} organisationId={org.id} />
        </Card>
      </Container.Column>
    </Container.Content>
  );
});

export default UserProfileForms;
