import Api from '@common/services/api';

export const CORE_RECEIVE_USER = 'core/RECEIVE_USER';

const fetchNetworkUser = (networkId: string, userId: string) => async (dispatch: any) => {
  const path = `/v1/networks/${networkId}/users/${userId}`;
  const { data: user } = await Api.get(path);
  return dispatch({
    type: CORE_RECEIVE_USER,
    user
  });
};

export default fetchNetworkUser;
