import React, {
  ComponentProps,
} from 'react';
import { useTranslation } from 'react-i18next';

import { PickFolderModal } from '../pick-folder-modal';
import { useThunkDispatch } from '@common/hooks';
import { AlertService } from '@services/alert';
import { moveDocument } from '../../actions';

import type { Document } from '../../types';
import type { PickFolderModalProps } from '../pick-folder-modal';

type MoveDocumentModalProps = Omit<PickFolderModalProps, 'onPick'> & {
  documentItem: Document;
};

export const MoveDocumentModal = ({
  children,
  documentItem,
  initialIsVisible,
  onClose,
}: MoveDocumentModalProps) => {
  const { t } = useTranslation();
  const thunkDispatch = useThunkDispatch();

  const translationContext = documentItem.is_folder ? 'folder' : 'file';

  const handleMoveFile: ComponentProps<typeof PickFolderModal>['onPick'] = async ({
    handleClose,
    selectedFolder,
  }) => {
    try {
      await thunkDispatch(moveDocument(documentItem.id, selectedFolder?.id));

      AlertService.success(t('documents:move_successfully', { context: translationContext }));
    } catch (e: any) {
      if (e && typeof e === 'object' && 'status_code' in e) {
        AlertService.forStatus(e.status_code, {
          warning: t('documents:move_warning', { context: translationContext }),
          error: t('documents:move_error', { context: translationContext }),
        });
      }
    }

    handleClose();
  };

  return (
    <PickFolderModal
      initialIsVisible={initialIsVisible}
      onClose={onClose}
      onPick={handleMoveFile}
    >
      {children}
    </PickFolderModal>
  );
};
