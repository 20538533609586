import React from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Group, Label, TextInput } from '@common/components/form';
import { useAppSelector } from '@common/hooks';
import { getWhiteLabelConfig } from '@modules/organisation/selectors/organisation';
import { IntegrationRedeemCode } from '../../../components/integration-redeem-code';

import type { ExtendedUser } from '@common/types/objects';
import { WhiteLabelInvitationMode } from '@common/definitions';

type ProfileSecuritySettingsProps = {
  user: ExtendedUser;
};

export const ProfileSecuritySettings = ({ user }: ProfileSecuritySettingsProps) => {
  const { t } = useTranslation();
  const shouldShowRedeemCode = useAppSelector(getWhiteLabelConfig)?.invitation_mode === WhiteLabelInvitationMode.CODE;

  return (
    <div className="ProfileSecuritySettings">
      <Row>
        <h1>{t('core:profile_settings_security_title')}</h1>
      </Row>

      <Row>
        <Group>
          <h2>{t('core:profile_settings_password_change_title')}</h2>
          <h3>{t('core:profile_settings_password_requirements')}</h3>
        </Group>
      </Row>

      <Row>
        <Group>
          <Label forInput="password_new" text={t('core:password_form_new')} />
          <TextInput
            name="password_new"
            type="password"
            autoComplete="off"
            data-lpignore="true"
          />
        </Group>
      </Row>

      <Row className="ProfileSecuritySettings__ConfirmPasswordRow">
        <Group>
          <Label forInput="password_repeat" text={t('core:password_form_repeat')} />
          <TextInput
            name="password_repeat"
            type="password"
            autoComplete="off"
            data-lpignore="true"
          />
        </Group>
      </Row>

      {shouldShowRedeemCode && (
        <>
          <hr />
          <IntegrationRedeemCode user={user} />
        </>
      )}
    </div>
  );
};
