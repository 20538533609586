import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useUserPermissions } from '@common/hooks';
import {
  DatePickerInput, Group, Label,
  PhoneNumberInput, Row, TextInput,
} from '@common/components/form';
import TextareaInput from '@common/components/form/inputs/textarea';
import { EPermissions } from '@common/definitions';
import { PrivacyFieldSelect } from '../../../components/privacy-select';
import { UserProfileTypes } from '../../../definitions';

import type { ExtendedUser } from '@common/types/objects';
import { ProfilePictureForm } from '../../profile-picture';

type ProfileGeneralSettingsProps = {
  user: ExtendedUser;
  type: UserProfileTypes;
  setProfilePicture: (...args: any[]) => any;
  updatedProfilePicture: Blob | null;
};

export const ProfileGeneralSettings = ({
  user, type, setProfilePicture, updatedProfilePicture
}: ProfileGeneralSettingsProps) => {
  const { t } = useTranslation();
  const { hasPermissions } = useUserPermissions();

  const isEditDisabled = !(
    hasPermissions([EPermissions.ORGANISATION_USERS_UPDATE, EPermissions.NETWORK_USERS_UPDATE], true)
    || type === UserProfileTypes.OWN
  );

  return (
    <div className="ProfileGeneralSettings">
      <Row>
        <h1>{t('core:profile_settings_general_title')}</h1>
      </Row>

      {type === UserProfileTypes.OWN && (
        <>
          <Row>
            <h2>{t('core:profile_list_picture_title')}</h2>
          </Row>
          {/* @ts-expect-error */}
          <ProfilePictureForm
            user={user}
            initialValues={user}
            setProfilePicture={setProfilePicture}
            updatedPicture={updatedProfilePicture}
          />
          <hr />
        </>
      )}

      <Row>
        <h1>{t('core:profile_personal_information')}</h1>
      </Row>

      <Row>
        <Group>
          <Label
            forInput="email"
            text={
              t(
                isEditDisabled ?
                  'core:profile_form_email_disabled' :
                  'core:profile_form_email'
              )
            }
          />
          <TextInput name="email" type="email" disabled={isEditDisabled} />
        </Group>
      </Row>

      <Row>
        <Group>
          <Label forInput="first_name" text={t('core:profile_form_first_name')} data-lpignore="true" />
          <TextInput name="first_name" />
        </Group>
      </Row>

      <Row>
        <Group>
          <Label forInput="last_name" text={t('core:profile_form_last_name')} data-lpignore="true" />
          <TextInput name="last_name" />
        </Group>
      </Row>

      {type === UserProfileTypes.OWN && (
        <Row>
          <Group>
            <Label forInput="biography" text={t('core:profile_form_biography')} />
            <TextareaInput
              name="bio"
              placeholder={t('core:profile_form_biography_placeholder')}
              minRows={3}
              disabled={type !== UserProfileTypes.OWN}
            />
          </Group>
        </Row>
      )}

      <Row>
        <Group>
          <Label forInput="date_of_birth" text={t('core:profile_form_dob')} />
          <DatePickerInput
            showYearDropdown
            showMonthDropdown
            name="date_of_birth"
            placeholder={t('core:profile_form_dob_placeholder')}
            maxDate={moment().subtract(12, 'years')}
          />
        </Group>
        {type === UserProfileTypes.OWN && (
          <div>
            <Label text={t('core:profile_form_dob_visibility')} />
            <PrivacyFieldSelect name="settings.show_date_of_birth" />
          </div>
        )}
      </Row>

      <Row>
        <Group>
          <Label forInput="phone" text={t('core:profile_form_phone_number')} />
          <PhoneNumberInput name="phone" placeholder={t('core:profile_form_phone_number_placeholder')} />
        </Group>
        {type === UserProfileTypes.OWN && (
          <div>
            <Label text={t('core:profile_form_phone_number_visibility')} />
            <PrivacyFieldSelect name="settings.show_phone_num" />
          </div>
        )}
      </Row>

      <Row>
        <Group>
          <Label
            forInput="scopes.organisation.active_until"
            text={t('core:profile_form_date_out_of_service')}
            data-lpignore="true"
          />
          <DatePickerInput
            showYearDropdown
            showMonthDropdown
            name="scopes.organisation.active_until"
            dropdownMode="select"
            placeholder={t('core:profile_form_dob_placeholder')}
          />
        </Group>
      </Row>

      <Row>
        <Group>
          <Label forInput="scopes.organisation.external_id" text={t('core:profile_form_unique_id')} />
          <TextInput name="scopes.organisation.external_id" disabled={isEditDisabled} />
        </Group>
      </Row>
    </div>
  );
};
