import React, { useState, useEffect, useRef } from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { Modal } from '@common/components/modal';
import { Button } from '@common/components/button';
import { ConfirmButton } from '@common/components/confirm-button';
import { AlertService } from '@services/alert';
import { TextInput } from '@common/components/form';
import { useThunkDispatch } from '@common/hooks';
import { renameDocument } from '../../actions';

import type { Document } from '../../types';

type FormState = {
  name: string;
};

type ReduxFormProps = InjectedFormProps<FormState, RenameDocumentOwnProps, boolean>;
const reduxFormConnector = reduxForm<FormState, RenameDocumentOwnProps, boolean>({
  form: 'rename-document',
  initialValues: {
    name: '',
  },
  validate: () => ({}),

});

type RenameDocumentOwnProps = {
  document: Document;
  onClose: () => void;
};

type RenameDocumentProps = RenameDocumentOwnProps & ReduxFormProps;

export const UnconnectedRenameDocumentModal = ({
  document,
  onClose,
  initialize,
  handleSubmit,
  dirty,
  submitting,
}: RenameDocumentProps) => {
  const { t } = useTranslation();
  const thunkDispatch = useThunkDispatch();
  const inputRef = useRef<HTMLInputElement>();
  const [isConfirmDiscardChangesVisible, setIsConfirmDiscardChangesVisible] = useState(false);

  useEffect(() => {
    initialize(document);
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      const { value } = inputRef.current;
      inputRef.current.focus();

      const extRegex = /\.\w{2,4}$/;
      if (extRegex.test(value)) {
        const extensionPosition = value.search(extRegex);
        inputRef.current.setSelectionRange(0, extensionPosition);
      }
    }
  }, [inputRef.current]);

  const submitHandler = handleSubmit(async ({ name }) => {
    const context = document.is_folder ? 'folder' : 'file';
    if (name === document.name) return onClose();

    try {
      await thunkDispatch(renameDocument(document.id, name));
      onClose();
    } catch (e: any) {
      if (e && typeof e === 'object' && 'status_code' in e) {
        AlertService.forStatus(e.status_code, {
          warning: t('documents:renamed_warning', { context }),
          error: t('documents:renamed_error', { context }),
        });
      }
    }
  });

  const handleCancel = () => {
    if (dirty) {
      return setIsConfirmDiscardChangesVisible(true);
    }

    onClose();
  };

  return (
    <>
      <Modal
        title={t('documents:rename')}
        wrapper={Modal.FormWrapper}
        wrapperProps={{ onSubmit: submitHandler }}
        size="small"
        onClose={handleCancel}
        content={(
          <TextInput name="name" ref={inputRef} />
        )}
        footer={(
          <Button
            buttonType="submit"
            type="primary"
            isLoading={submitting}
            disabled={!dirty}
          >
            {t('common:change')}
          </Button>
        )}
      />
      {isConfirmDiscardChangesVisible && (
        <ConfirmButton
          title={t('documents:rename_discard')}
          onConfirm={onClose}
          onCancel={() => setIsConfirmDiscardChangesVisible(false)}
          confirmText={t('documents:do_not_save')}
        />
      )}
    </>
  );
};

export const RenameDocumentModal = reduxFormConnector(UnconnectedRenameDocumentModal);
