import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Label,
  Group,
  Row,
  LanguageInput,
} from '@common/components/form';
import { PrivacyFieldSelect } from '../../../components/privacy-select';
import Permission from '@common/components/permission';
import { EComponentTypes } from '../../../definitions';

export const ProfilePreferencesSettings = () => {
  const { t } = useTranslation();

  return (
    <div className="ProfilePreferencesSettings">
      <Row>
        <h1>{t('core:profile_settings_preferences_title')}</h1>
      </Row>

      <Row>
        <Group>
          <Label text={t('core:language_form_label')} />
          {/* @ts-expect-error */}
          <LanguageInput name="language.locale" />
        </Group>
      </Row>
      <Permission component={EComponentTypes.FEED_TRANSLATION}>
        <Row>
          <Group>
            <Label text={t('core:translation_language_form_label')} />
            {/* @ts-expect-error */}
            <LanguageInput translations name="translation_language.locale" />
          </Group>
        </Row>
      </Permission>

      <hr />

      <Row>
        <Group>
          <h1>{t('core:profile_settings_privacy_title')}</h1>
          <h3>{t('core:privacy_form_description')}</h3>
        </Group>
      </Row>

      <Row>
        <Group>
          <Label text={t('core:user_detail_phone_number')} />
          <PrivacyFieldSelect name="settings.show_phone_num" props={{ onlyBoolean: true }} />
        </Group>
      </Row>

      <Row className="ProfilePreferencesSettings__DOBRow">
        <Group>
          <Label text={t('core:profile_form_dob')} />
          <h3>{t('core:we_dont_display_dob_disclaimer')}</h3>
          <PrivacyFieldSelect name="settings.show_date_of_birth" />
        </Group>
      </Row>

      <Row>
        <Group>
          <Label text={t('core:privacy_form_last_active_title')} />
          <PrivacyFieldSelect name="settings.show_last_active" />
        </Group>
      </Row>

      <Row>
        <Group>
          <Label text={t('core:privacy_form_chat_title')} />
          <PrivacyFieldSelect name="settings.allow_chat_invites" />
        </Group>
      </Row>
    </div>
  );
};
