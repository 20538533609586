import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@common/components/button';
import { combineClassNames } from '@common/utils/combineClassNames';

type DefaultProps = {
  disabled: boolean;
  className?: string;
  blur?: 'none' | 'light' | 'medium' | 'heavy';
  opacity?: 'none' | 'light' | 'medium';
  greyedOut?: boolean;
};

type Props = PropsWithChildren<DefaultProps & ({
  showUpgradeModal: (props?: any) => void;
  overlayContent?: undefined;
} | {
  showUpgradeModal?: undefined;
  overlayContent: JSX.Element;
})>;

type GetContentClassNameProps = {
  disabled: boolean;
  className: string;
  opacity: DefaultProps['opacity'];
  blur: DefaultProps['blur'];
};

const getContentClassName = ({ className, disabled, opacity, blur }: GetContentClassNameProps) => {
  if (!disabled) return className;
  return combineClassNames(`${className} PlanPackageOverlay__Content--disabled`, {
    [`PlanPackageOverlay__Content--opacity-${opacity}`]: opacity !== 'none',
    [`PlanPackageOverlay__Content--blur-${blur}`]: blur !== 'none',
  });
};

/**
 * See Storybook story for documentation on decoupeling the overlay
 * from the plan package logic.
 */
const PlanPackageOverlay = ({
  opacity = 'light', blur = 'light', className = '', greyedOut = false, disabled,
  overlayContent, children, showUpgradeModal,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="PlanPackageOverlay">
      <div className={(getContentClassName({ disabled, className, opacity, blur }))}>
        {(disabled && greyedOut && (
          <div className="PlanPackageOverlay__Content--greyed-out">
            {children}
          </div>
        )) || children}
      </div>
      {disabled && (overlayContent || (
        <div className="PlanPackageButton">
          <Button type="primary" size="large" onClick={showUpgradeModal}>
            {t('pricing:upgrade_plan')}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default PlanPackageOverlay;
