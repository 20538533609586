// import * as validation from '../../../../common/utils/validation';
import i18n from '../../../../i18n';

export default (values) => {
  const errors = {};

  if (!values?.first_name) errors.first_name = true;
  if (!values?.last_name) errors.last_name = true;
  if (!values?.email) errors.email = true;
  // if (!validation.test('PHONE_NUM', values.phone_num)) errors.phone_num = true;
  if (values?.password_new && values.password_new !== values.password_repeat) {
    errors.password_repeat = i18n.t('core:passwords_must_match');
  }

  return errors;
};
