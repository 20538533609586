import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { reduxForm, InjectedFormProps, FormSubmitHandler } from 'redux-form';
import * as R from 'ramda';

import { Modal } from '@common/components/modal';
import Alert from '@common/components/alert';
import { Button } from '@common/components/button';
import PlanPackageOverlay from '@common/components/plan-package-overlay/plan-package-overlay';
import {
  Label, TextInput, SelectInput, Row, Group,
} from '@common/components/form';

import { useThunkDispatch } from '@hooks/redux';
import { useFormValues, useToggle } from '@hooks/index';
import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import { AlertService } from '@services/alert';
import { isEqualWithFlatDiff } from '@utils/isEqualWithFlatDiff';
import { createFolder, updateDocument } from '../../actions';
import type { CreateDocumentPayload } from '../../actions';
import { validateFolderForm } from '../../validators/folder_validation_form';
import * as loggedUserSelector from '../../../core/selectors/logged-user';

import type { Folder } from '../../types';
import type { StoreState } from '@common/types/store';
import { EPlanPackageConfig } from '@common/definitions';

export type FolderFormValues = Pick<Folder, 'name' | 'access_settings' | 'parent_folder_id' | 'is_folder'>;

export const FOLDER_FORM_NAME = 'folder-form';

export const folderReduxForm = reduxForm<FolderFormValues, FolderModalOwnProps>({
  form: FOLDER_FORM_NAME,
  validate: validateFolderForm,
  enableReinitialize: true,
});

const mapStateToProps = (state: StoreState) => ({
  networks: loggedUserSelector.networks(state),
  // @ts-expect-error
  functions: loggedUserSelector.functions(state, true),
});

const connector = connect(mapStateToProps);

export const parseFolderStateToPayload = (
  state: Partial<FolderFormValues>,
  parentFolderId?: string,
): CreateDocumentPayload => ({
  ...state,
  name: state.name || '',
  is_folder: true,
  parent_folder_id: (parentFolderId !== 'personal' && parentFolderId !== undefined) ? parentFolderId : null,
  access_settings: {
    functionIds: state.access_settings?.functions?.map?.(({ id }) => id) || [],
    networkIds: state.access_settings?.networks?.map?.(({ id }) => id) || [],
  },
});

type FolderModalOwnProps = Partial<{
  folder: Folder | undefined; // Folder we're currently changing
  currentFolder: Folder; // Folder we're currently in
  handleHide: () => void;
  onClose: () => void;
  children: React.ReactElement;
  initialIsVisible: boolean;
  userId?: string;
}>;

type FolderModalProps = ConnectedProps<typeof connector>
& InjectedFormProps<FolderFormValues>
& FolderModalOwnProps;

export const UnconnectedFolderModal = ({
  folder,
  form,
  currentFolder,
  userId,
  initialValues,
  onClose,
  valid,
  handleSubmit,
  children,
  initialIsVisible = false,
  destroy,
  networks,
  functions,
  submitting,
}: FolderModalProps) => {
  const { t } = useTranslation();
  const thunkDispatch = useThunkDispatch();
  const [isVisible, toggleIsVisible] = useToggle(initialIsVisible);
  const values = useFormValues<FolderFormValues>(form);

  const {
    isAvailable: canSetFolderSpecificPermissions, showUpgradeModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.FOLDER_SPECIFIC_PERMISSIONS);

  const handleClose = () => {
    toggleIsVisible();
    onClose?.();
    destroy();
  };

  const submitFn: FormSubmitHandler<FolderFormValues> = async (newValues) => {
    if (folder) {
      const parentFolderId = newValues.parent_folder_id || currentFolder?.id;
      const asPayload = parseFolderStateToPayload(newValues, parentFolderId);

      const [isEqual, diff] = isEqualWithFlatDiff(asPayload, parseFolderStateToPayload(initialValues, parentFolderId));
      if (isEqual || !diff) {
        AlertService.success(t('documents:updated_folder_successfully'));
        return handleClose();
      }

      try {
        await thunkDispatch(updateDocument(diff, folder.id, userId));
        AlertService.success(t('documents:updated_folder_successfully'));
      } catch (e: any) {
        if (e && typeof e === 'object' && 'status_code' in e) {
          AlertService.forStatus(e.status_code, {
            error: t('documents:error_while_updating_folder'),
            warning: t('documents:warning_while_updating_folder'),
          });
        }
      }
    } else {
      try {
        await thunkDispatch(createFolder(parseFolderStateToPayload(newValues, currentFolder?.id), userId));
        AlertService.success(t('documents:created_folder_successfully'));
      } catch (e: any) {
        if (e && typeof e === 'object' && 'status_code' in e) {
          AlertService.forStatus(e.status_code, {
            error: t('documents:error_while_creating_folder'),
            warning: t('documents:warning_while_creating_folder'),
          });
        }
      }
    }

    handleClose();
  };

  const filterGroups = (folderGroups: { id: string }[] | undefined, userGroups: { id: string }[]) => () => {
    if (folderGroups && !R.isEmpty(folderGroups)) {
      return folderGroups?.filter(({ id }) => userGroups.some((userGroup) => userGroup.id === id));
    }

    return userGroups;
  };

  const pickableNetworks = useMemo(
    filterGroups(currentFolder?.access_settings?.networks, networks),
    [currentFolder, networks],
  );

  const pickableFunctions = useMemo(
    filterGroups(currentFolder?.access_settings?.functions, functions),
    [currentFolder, functions],
  );

  const isUserFolder = !!(userId || currentFolder?.personal_root_user_id || currentFolder?.type === 'personal_folder');
  const hasPermissionSettings = useMemo(() => {
    return values?.access_settings?.functions?.length || values?.access_settings?.networks?.length;
  }, [values]);
  const specificPermissionsDisabled = !canSetFolderSpecificPermissions && !hasPermissionSettings;

  return (
    <Modal
      list
      show={isVisible}
      onClose={handleClose}
      className="FolderModal"
      title={t('documents:new_folder')}
      wrapper={Modal.FormWrapper}
      wrapperProps={{ onSubmit: handleSubmit(submitFn) }}
      footer={(
        <Button buttonType="submit" type="primary" disabled={!valid} isLoading={submitting}>
          {t(folder ? 'documents:update_folder' : 'documents:create_folder')}
        </Button>
      )}
      content={(
        <>
          <Row>
            <Group>
              <Label text={t('documents:folder_name')} forInput="name" />
              <TextInput name="name" />
            </Group>
          </Row>
          {!isUserFolder && (
            <PlanPackageOverlay
              greyedOut
              opacity="medium"
              disabled={specificPermissionsDisabled}
              showUpgradeModal={showUpgradeModal}
            >
              <Row>
                <Group>
                  <Group>
                    <Label text={t('documents:access')} />
                  </Group>
                  {!specificPermissionsDisabled && <Alert type="warning" title={t('documents:folder_form_access_warning')} />}
                </Group>
              </Row>
              <Row>
                <Group>
                  <Label text={t('common:predicates_filter_column_communities_label')} forInput="network" />
                  <SelectInput
                    multi
                    searchable
                    name="access_settings.networks"
                    placeholder={t('documents:add_community_placeholder')}
                    options={pickableNetworks}
                    disabled={specificPermissionsDisabled}
                    valueKey="id"
                    labelKey="name"
                  />
                </Group>
              </Row>
              <Row>
                <Group>
                  <Label text={t('common:predicates_filter_column_functions_label')} forInput="function" />
                  <SelectInput
                    multi
                    searchable
                    name="access_settings.functions"
                    placeholder={t('documents:add_function_placeholder')}
                    options={pickableFunctions}
                    disabled={specificPermissionsDisabled}
                    valueKey="id"
                    labelKey="name"
                  />
                </Group>
              </Row>
            </PlanPackageOverlay>
          )}
        </>
      )}
    >
      {children && React.cloneElement(children, { onClick: toggleIsVisible })}
    </Modal>
  );
};

export const FolderModalForm = folderReduxForm(connector(UnconnectedFolderModal));
