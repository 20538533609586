import * as React from 'react';
import Icon from '@common/components/icon';
import { EQuestionTypes } from '../../definitions';

type Props = {
  type: string,
};

export const QuestionIcon = ({ type }: Props) => {
  switch (type) {
    case EQuestionTypes.TEXT:
      return <Icon type="chat__filled" className="QuestionIcon QuestionIcon--type-text" />;
    case EQuestionTypes.MULTIPLE_CHOICE:
      return <Icon type="check" className="QuestionIcon QuestionIcon--type-multiple-choice" />;
    case EQuestionTypes.SLIDER:
      return <Icon type="linear_scale" className="QuestionIcon QuestionIcon--type-slider" />;
    case EQuestionTypes.CONTENT:
      return <Icon type="subject" className="QuestionIcon QuestionIcon--type-content" />;
    case EQuestionTypes.DATE_TIME:
      return <Icon type="calendar_today__filled" className="QuestionIcon QuestionIcon--type-date-time" />;
    case EQuestionTypes.NUMBER:
      return <Icon type="number__filled" className="QuestionIcon QuestionIcon--type-number" />;
    case EQuestionTypes.FILE_UPLOAD:
      return <Icon type="attach_file" className="QuestionIcon QuestionIcon--type-file-upload" />;
    case EQuestionTypes.DROPDOWN:
      return <Icon type="arrow_circle_down__filled" className="QuestionIcon QuestionIcon--type-dropdown" />;
    case EQuestionTypes.BOOLEAN:
      return <Icon type="check" className="QuestionIcon QuestionIcon--type-boolean" />;
    case EQuestionTypes.CHECKLIST:
      return <Icon type="check_circle__filled" className="QuestionIcon QuestionIcon--type-checklist" />;
    default:
      return null;
  }
};
