// @ts-expect-error
export const validateFolderForm = (values) => {
  const errors: Record<string, boolean> = {};

  if (!values.name) {
    errors.name = true;
  }

  return errors;
};
