import { createSelector } from 'reselect';

import { predicatesToFilters } from '@common/utils/predicates';
import * as screensReducer from '../learning/reducers/screens';
import { EQuestionTypes } from '../survey/definitions';

import type { StoreState } from '@common/types/store';
import type { Attachment } from '@common/types/objects';
import type { Screen, ScreenComponentItem } from '../learning/types/objects';
import type {
  FormDetail,
  Form,
  FormStored,
  FormCounts,
  AnswerValue,
} from './types';

const getFormIds = (state: StoreState) => state.forms.ids;
const getFolderItems = (state: StoreState) => state.forms.items;
const getFormSubmission = (state: StoreState, id: string) => state.forms.submissions.items[id];
const getSubmissionDetail = (state: StoreState, id: string) => state.forms.submissions.details[id];
const getSubmissionsForForm = (state: StoreState, id: string) => state.forms.submissions.ids[id];
const getAllSubmissions = (state: StoreState) => state.forms.submissions.items;
const getState = (state: StoreState) => state;

const createForm = (item: FormStored | undefined, state: StoreState): Form | undefined => (item && {
  ...item,
  created_by: state.users.items[item.created_by],
  filters: predicatesToFilters(item?.audience?.predicates || [], state),
});

export const createDetailedForm = (item: FormStored | undefined, state: StoreState): FormDetail | undefined => {
  const simpleForm = createForm(item, state);
  if (!simpleForm || !item) return undefined;

  const screens = (item.screen_ids || [])
    .map((id) => screensReducer.findById(state, id))
    .filter((s) => !!s) as Screen[];

  return {
    ...simpleForm,
    screens: screens.sort((a, b) => a.index - b.index),
  };
};

export const getForms = createSelector(
  [getFormIds, getFolderItems, getState],
  (ids, formItems, state): Form[] => {
    if (!ids) return [];

    return ids.reduce<Form[]>((acc, id: string) => {
      const form = createForm(formItems[id], state);

      if (!form) return acc;

      return [...acc, form];
    }, []);
  },
);

const getFormFromState = (state: StoreState, id: string) => state.forms.items[id];
export const getMetaFromState = (st: StoreState, id: string) => st.forms.meta[id];

export const getFormModerators = createSelector(
  [getMetaFromState, getFormFromState],
  (meta: any, state: any) => {
    // console.log("debug meta", meta);
    // console.log("debug state", state);
    const users = meta?.related?.users || [];
    const moderators = state?.settings?.moderators?.map((data: any) => {
      const user = users.find((u: any) => u.id === data.user_id);
      return {
        ...user,
        ...data
      };
    }) || [];
    return moderators;
  }
);

export const getForm = createSelector(
  [getFormFromState, getState],
  (form, state) => createDetailedForm(form, state),
);

const getCountsFromState = (state: StoreState) => state.forms.counts;

export const getCounts = createSelector(
  [getCountsFromState],
  (counts): FormCounts => counts,
);

const getFormTemplates = (state: StoreState) => state.forms.templates;

export const getTemplates = createSelector(
  [getFormTemplates],
  (templates) => templates,
);

export const getSubmissions = createSelector(
  [getSubmissionsForForm, getAllSubmissions],
  (ids, submissions) => {
    if (!ids) return [];

    return ids.map((id) => submissions[id]);
  },
);

const getQuestionByScreens = (screens: Screen[], id: string) => {
  const screen = screens.find((s) => s.components.some((component) => component.id === id));

  if (!screen) return undefined;

  return screen.components.find((component) => component.id === id);
};

const getAnswer = (value: AnswerValue, question: ScreenComponentItem, attachments: Attachment[]) => {
  if (question.type === EQuestionTypes.FILE_UPLOAD && Array.isArray(value)) {
    return value.map((attachmentId) => attachments.find((attachment) => attachment.id === attachmentId));
  }

  return value;
};

export const formatSubmission = (submission: any, detail: any) => {
  // console.log("debug formatSubmission submission", submission);
  // console.log("debug formatSubmission detail", detail);
  if (!submission || !detail) {
    return undefined;
  }
  return {
    ...submission,
    form: detail.form,
    answers: detail.answers.map(({ question_id: id, value }: { question_id: string, value: any }) => {
      const question = getQuestionByScreens(detail.form.screens, id);

      if (!question) return undefined;

      const answer = getAnswer(value, question, detail.attachments);

      return {
        id,
        question,
        answer,
      };
    }),
    response_status_activities: detail.response_status_activities
  };
};

export const getSubmission = createSelector(
  [getFormSubmission, getSubmissionDetail],
  formatSubmission
);

export const getSubmissionMeta = (state: StoreState, submissionId: string) => {
  return state?.forms?.submissions?.meta?.[submissionId];
};
