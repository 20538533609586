import React from 'react';
import { useTranslation } from 'react-i18next';
import Placeholder from '@common/components/placeholder';

type Props = {
  filter?: string;
};

export const DocumentsPlaceholder = ({ filter }: Props) => {
  const { t } = useTranslation();

  return (
    <Placeholder
      image="/static/images/placeholders/files.svg"
      title={t('documents:no_files', { context: filter })}
      description={t('documents:no_files_description', { context: filter })}
    />
  );
};
