import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm, InjectedFormProps } from 'redux-form';

import OrganisationAdminForm from '../../../admin/forms/admin';
import { Icon } from '@common/components/icon';
import { SelectInput, Label } from '@common/components/form';
import { Button } from '@common/components/button';
import { mergeNamesToString } from '@utils/merged-names-text';
import { useAppSelector, useThunkDispatch, useFormValues } from '@common/hooks';
import { AlertService } from '@services/alert';
import * as organisationSelectors from '../../../organisation/selectors/organisation';
import { validateAddUserToCommunity } from '../validators/add-user-to-community';
import { fetchPermissionGroups } from '../../../organisation/actions';
import { fetchRoles } from '../../../network/actions';
import { addUserToCommunity } from '../../actions';

import type { ExtendedUser, Role } from '@common/types/objects';

export type AddToCommunityFormState = {
  network?: string;
  roles: string[];
};

type AddFormOwnProps = {
  user: ExtendedUser;
};

const addToCommunityFormConnector = reduxForm<AddToCommunityFormState, AddFormOwnProps, boolean>({
  form: 'add-user-to-community',
  initialValues: {
    network: undefined,
    roles: [],
  },
  validate: validateAddUserToCommunity,
});

type AddToCommunityReduxFormProps = InjectedFormProps<AddToCommunityFormState, AddFormOwnProps, boolean>;

type AddToCommunityFormProps = AddFormOwnProps & AddToCommunityReduxFormProps;

export const UnconnectedAddUserToCommunity = ({
  user,
  handleSubmit,
  form,
  change,
  submitting,
  reset,
}: AddToCommunityFormProps) => {
  const { t } = useTranslation();
  const thunkDispatch = useThunkDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const networks = useAppSelector(organisationSelectors.networks);
  const { network: networkRoles } = useAppSelector(organisationSelectors.roles);
  const formValues = useFormValues<AddToCommunityFormState>(form);

  const selectedNetwork = formValues.network
    ? networks.find(({ id }) => id === formValues.network)
    : undefined;

  const selectedRoles = networkRoles.filter(({ id }) => formValues.roles.includes(id));

  useEffect(() => {
    if (formValues.network) {
      thunkDispatch(fetchRoles(formValues.network));
    }
  }, [formValues.network]);

  const submitFunction = async () => {
    if (!selectedNetwork) return;

    try {
      // @ts-expect-error
      await thunkDispatch(addUserToCommunity(user.id, selectedNetwork, selectedRoles));
      AlertService.success(t('core:add_to_community', { context: 'success' }));
      setIsOpen(false);
      reset();
    } catch (e: any) {
      if (e && typeof e === 'object' && 'status_code' in e) {
        AlertService.forStatus(e.status_code, {
          warning: t('core:add_to_community', { context: 'warning' }),
          error: t('core:add_to_community', { context: 'error' }),
        });
      }
    }
  };

  if (!isOpen) {
    return (
      <span className="AddUserToCommunity__ShowButton" onClick={() => setIsOpen(true)}>
        {t('core:add_to_community')}
      </span>
    );
  }

  return (
    <form className="AddUserToCommunity" onSubmit={handleSubmit(submitFunction)}>
      <Label text={t('core:add_community')} />
      { /* do not rely on user.scopes[].memberships[], see comments on PD-8672 */ }
      <SelectInput
        name="network"
        options={networks.filter(({ id }) => !user.scopes.networks.some((userNetwork) => userNetwork.id === id))}
        labelKey="name"
        valueKey="id"
      />

      {selectedNetwork && (
        <OrganisationAdminForm
          // @ts-expect-error
          isSelect
          admin={{ ...user, roles: selectedRoles }}
          roles={networkRoles}
          network={selectedNetwork}
          onChange={(roles: Role[]) => change('roles', roles.map(({ id }) => id))}
          fetchGroups={(level: number) => thunkDispatch(fetchPermissionGroups(level))}
        >
          <div className="AddUserToCommunity__RolesPicker">
            {mergeNamesToString(selectedRoles.map(({ name }) => name)) || (
              <span className="AddUserToCommunity__RolesPickerPlaceholder">
                {t('core:select_community_role_placeholder')}
              </span>
            )}
            <Icon type="edit__filled" />
          </div>
        </OrganisationAdminForm>
      )}

      <div className="AddUserToCommunity__ActionButtons">
        <Button onClick={() => setIsOpen(false)}>
          {t('common:confirm_button_cancel')}
        </Button>
        <Button type="primary" buttonType="submit" isLoading={submitting}>
          {t('core:add')}
        </Button>
      </div>
    </form>
  );
};

export const AddUserToCommunity = addToCommunityFormConnector(UnconnectedAddUserToCommunity);
