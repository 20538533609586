import validateUser from './user';
import * as validationUtil from '../../../../common/utils/validation';
import i18n from '../../../../i18n';


export default function validateProfileSecurity(values: Record<string, any>) {
  let errors = {};

  if (values?.password_new && !validationUtil.test('PASSWORD', values.password_new)) {
    // @ts-expect-error
    errors.password_new = i18n.t('authentication:password_requirements');
  }

  errors = {
    ...errors,
    ...validateUser(values)
  };

  // console.log("debug errors", errors);
  return errors;
}
