import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@common/components/icon';
import { EPrivacyLevels } from '@common/definitions';

import type { IconTypes } from '@common/components/icon';

type PrivacyIndicatorProps = {
  privacyLevel: EPrivacyLevels;
};

export const PrivacyIndicator = ({ privacyLevel }: PrivacyIndicatorProps) => {
  const { t } = useTranslation();

  const privacyLevelMapping: Record<EPrivacyLevels, { label: string; icon: IconTypes }> = {
    [EPrivacyLevels.ORGANISATION]: { label: t('core:privacy_option_everyone'), icon: 'group__filled' },
    [EPrivacyLevels.NETWORK]: { label: t('core:privacy_option_network'), icon: 'business__filled' },
    [EPrivacyLevels.NOBODY]: { label: t('core:privacy_option_no_one'), icon: 'lock__filled' },
  };

  return (
    <div className="PrivacyIndicator">
      <Icon type={privacyLevelMapping[privacyLevel].icon} />
      <span>{privacyLevelMapping[privacyLevel].label}</span>
    </div>
  );
};
