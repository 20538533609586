import React, { useMemo } from 'react';
import { InjectedFormProps } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { Row, Group } from '@common/components/form';
import { Button } from '@common/components/button';
import { ImageInput } from '@common/components/form/inputs/image';
import type { ExtendedUser } from '@common/types/objects';


type OwnProps = {
  user: ExtendedUser;
  setProfilePicture: (...args: any[]) => any;
  updatedPicture: Blob | null;
};

type ProfilePictureFormProps = InjectedFormProps<{}, OwnProps> & OwnProps;

export const ProfilePictureForm = ({
  user,
  setProfilePicture,
  updatedPicture
}: ProfilePictureFormProps) => {
  const { t } = useTranslation();

  const updatedPic = useMemo(() => {
    return updatedPicture && URL.createObjectURL(updatedPicture);
  }, [updatedPicture]);

  return (
    <div className="Form Form--horizontal PictureForm">
      <div className="Form__Left">
        <Row>
          <Group>
            <Row className="Form__Row PictureForm__Row">
              <div className="Form__Row__Preview">
                <img
                  src={updatedPic || user.profile_img || ''}
                  alt={user.full_name}
                />
              </div>
              <div>
                <ImageInput
                  onSubmit={setProfilePicture}
                  submitButtonText={t('core:confirm')}
                >
                  <Button type="default">{t('common:select_image')}</Button>
                </ImageInput>
              </div>
            </Row>
          </Group>
        </Row>
      </div>
    </div>
  );
};
