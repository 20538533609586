import React, {
  memo, useCallback, useMemo, useState, useEffect, PropsWithChildren
} from 'react';
import Container from '@common/components/container';
import Card from '@common/components/card';
import type { ExtendedUser, LoggedUser } from '@common/types/objects';
import { useTranslation } from 'react-i18next';
import { Api } from '@common/services/api';
import { selected } from '@modules/organisation/selectors/organisation';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Placeholder from '@common/components/placeholder/placeholder';
import { AsyncList } from '@common/components/list';
import { Icon } from '@common/components/icon';
import { Overview, OverviewContent } from '@common/components/overview';
import { ProgressBar } from '@common/components/progress-bar/progress-bar';
import Spinner from '@common/components/spinner';
import { Bar } from '@common/components/bar';
import ModuleIcon from '@modules/learning/components/module-icon';
import Tooltip from '@common/components/tooltip';

type RowProps = PropsWithChildren<{
  className?: string;
}>;

export const Row = memo(({ children, className }: RowProps) => {
  let clName = 'Table__Row';
  if (className) {
    clName += ` ${className}`;
  }
  return <div className={clName}>{ children }</div>;
});

type DeletionTooltipProps = PropsWithChildren<{
  disabled: boolean;
}>;

const DeletionTooltip = memo(({ disabled, children }: DeletionTooltipProps) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={t('core:this_course_is_not_available_anymore')}
      placement="bottom"
      disabled={disabled}
    >
      { children }
    </Tooltip>
  );
});

type CourseCellProps = {
  setOpen: (open: boolean) => void;
  item: any;
  open: boolean;
};

export const CourseCell = memo(({ setOpen, item, open }: CourseCellProps) => {
  return (
    <div className="Table__Cell courseCell">
      <Icon
        className="courseToggle"
        type={open ? 'expand_more' : 'chevron_right'}
        onClick={() => setOpen(!open)}
      />
      <DeletionTooltip disabled={!item.deleted_at}>
        <div
          className="courseImage"
          style={{
            backgroundImage: `url(${item.header_image_url})`
          }}
        />
      </DeletionTooltip>
      <DeletionTooltip disabled={!item.deleted_at}>
        <span className="courseName">{ item?.name }</span>
      </DeletionTooltip>
      {
        item.deleted_at && (
          <DeletionTooltip disabled={!item.deleted_at}>
            <Icon className="courseDeletedIcon" type="warning" />
          </DeletionTooltip>
        )
      }
    </div>
  );
});

// TODO table row should be a reusable component

const tableRow = ({ item, userId }: any) => {
  const startedAt = item?.progress?.started_at;
  const finishedAt = item.progress?.finished_at;
  const currentPoints = item?.progress?.current_points;
  const maxPoints = item?.progress?.max_points;

  const [open, setOpen] = useState(false);

  const [courseInfo, setCourseInfo] = useState<any>(null);

  const organisationId = item?.organisation_id;
  const courseId = item?.id;
  useEffect(() => {
    if (open && !courseInfo) {
      // GET {{base_url}}/v2/organisations/180/users/4/courses/{courseId}
      const base = `/v2/organisations/${organisationId}`;
      const url = `${base}/users/${userId}/courses/${courseId}?type=onboarding`;
      Api.get(url).then((result: any) => {
        // console.log("debug result", result);
        setCourseInfo(result);
      });
    }
  }, [open, courseInfo, organisationId, userId, courseId, setCourseInfo]);

  const modules = useMemo(() => {
    return courseInfo?.data?.sections?.map((section: any) => {
      return section?.modules;
    }).flat();
  }, [courseInfo]);

  // console.log("debug modules", modules);

  return (
    <>
      <Row className={item.deleted_at ? 'deleted' : ''}>
        <CourseCell open={open} setOpen={setOpen} item={item} />
        <div className="Table__Cell">
          { startedAt ? moment(startedAt).format('D MMM YYYY') : '-' }
        </div>
        <div className="Table__Cell">
          { finishedAt ? moment(finishedAt).format('D MMM YYYY') : '-' }
        </div>
        <div className="Table__Cell">
          { currentPoints + '/' + maxPoints }
        </div>
      </Row>
      {
        open ?
          (
            modules ?
              modules.map((module: any) => {
                const progress = module?.progress;
                const start = progress?.started_at;
                const finished = progress?.finished_at;
                return (
                  <Row key={module.id} className="moduleRow">
                    <div className="Table__Cell courseCell">
                      <ModuleIcon module={module} />
                      <span>{ module?.name }</span>
                    </div>
                    <div className="Table__Cell">
                      { start ? moment(start).format('D MMM YYYY') : '-' }
                    </div>
                    <div className="Table__Cell">
                      { finished ? moment(finished).format('D MMM YYYY') : '-' }
                    </div>
                    <div className="Table__Cell">
                      {/* eslint-disable-next-line no-unsafe-optional-chaining */}
                      { progress?.current_points + '/' + progress?.max_points }
                    </div>
                  </Row>
                );
              }) :
              <Spinner centered />
          ) :
          null
      }
    </>
  );
};

type OnboardingTableProps = {
  organisationId: string;
  userId: string;
  setCourses: (courses: any) => void;
};

const OnboardingTable = memo(({
  organisationId,
  userId,
  setCourses
}: OnboardingTableProps) => {
  const { t } = useTranslation();

  const onFetch = useCallback(async () => {
    const url = `/v2/organisations/${organisationId}/users/${userId}`;
    const courses = await Api.get(`${url}/courses?type=onboarding`);
    // this does not set the table rows, but the jumbotron data above the table
    setCourses(courses);
    return courses;
  }, [organisationId, userId, setCourses]);

  const columns = useMemo(() => {
    return [{
      label: t('core:course_header')
    }, {
      label: t('core:course_header_started')
    }, {
      label: t('learning:course_statistics_column_finished')
    }, {
      label: t('core:course_header_score')
    }];
  }, [t]);

  return (
    <AsyncList
      containerClassName="Table expandableRowsTable"
      header={(
        <div className="Table__Row Table__Header">
          {
            columns.map((column: any) => {
              return (
                <div className="Table__Cell" key={column.label}>
                  <h5>{ column.label }</h5>
                </div>
              );
            })
          }
        </div>
      )}
      columns={columns}
      data={{
        // @ts-expect-error
        useCursor: false,
        // @ts-expect-error
        onFetch
      }}
      renderRow={tableRow}
      rowProps={{ userId }}
      placeholder={<Placeholder title={t('common:select_no_results')} />}
    />
  );
});

type UserProfileOnboardingProps = {
  user: ExtendedUser | LoggedUser;
};

const UserProfileOnboarding = memo(({ user }: UserProfileOnboardingProps) => {
  const org = useSelector(selected);

  const userId = user.id;
  const orgId = org.id;

  const [courses, setCourses] = useState<any>(null);

  // null if not fetched yet, "" if fetched but value is falsy (null),
  // date string if value is found
  const [deadline, setDeadline] = useState<null | string | ''>(null);

  useEffect(() => {
    const url = `/v4/organisations/${orgId}/users/${userId}`;
    Api.get(url).then((response: any) => {
      const dueDate = response?.data?.membership?.onboarding?.due_date || '';
      setDeadline(dueDate);
    });
  }, [userId, orgId, setDeadline]);

  // console.log("debug courses", courses);

  const jumbotronData = useMemo(() => {
    const data = courses?.data;

    if (!Array.isArray(data) || typeof deadline !== 'string') {
      return null;
    }

    const totalCompletedCourses = data.filter((course: any) => {
      return course?.progress?.finished_at;
    }).length;

    const totalMaxPoints = data.reduce((accumulator: number, course: any) => {
      return accumulator + (course?.progress?.max_points || 0);
    }, 0) || 0;

    const totalCurrentPoints = data.reduce((accumulator: number, course: any) => {
      return accumulator + (course?.progress?.current_points || 0);
    }, 0) || 0;

    const totalCourses = data?.length || 0;

    return {
      progressPercentage: Math.floor((totalCompletedCourses / totalCourses) * 100),
      progressFormatter: () => {
        return (
          <span className="progressFormatter">
            {totalCompletedCourses}/{totalCourses}
            <small>courses</small>
          </span>
        );
      },

      scorePercentage: Math.floor((totalCurrentPoints / totalMaxPoints) * 100),
      scoreFormatter: () => `${totalCurrentPoints}/${totalMaxPoints}`,

      deadlineDescription: (
        deadline ? moment(deadline).fromNow() : '-'
      ),
      deadlineFormatter: () => {
        if (deadline) {
          const date = moment(deadline);
          return date.format('LL');
        }
        return '-';
      }
    };
  }, [courses, deadline]);

  const { t } = useTranslation();

  return (
    <Container.Content className="UserProfileOnboarding">
      <Bar>
        <h2>{ t('learning:onboarding_courses_overview') }</h2>
      </Bar>
      <Container.Column>
        <Overview>
          <OverviewContent>
            {
              jumbotronData ?
                (
                  <OverviewContent.Row>
                    <OverviewContent.Row.StatisticsCard
                      icon="check"
                      color="#007AFF"
                      title={t('core:progress')}
                      count={Infinity}
                      formatter={jumbotronData.progressFormatter}
                      description={(
                        <ProgressBar
                          status={jumbotronData.progressPercentage}
                          size="large"
                        />
                      )}
                    />
                    <OverviewContent.Row.Divider />
                    <OverviewContent.Row.StatisticsCard
                      icon="star"
                      color="#007AFF"
                      title={t('core:course_header_score')}
                      count={Infinity}
                      formatter={jumbotronData.scoreFormatter}
                      description={(
                        <ProgressBar
                          status={jumbotronData.scorePercentage}
                          size="large"
                        />
                      )}
                    />
                    <OverviewContent.Row.Divider />
                    <OverviewContent.Row.StatisticsCard
                      icon="access_time"
                      color="#007AFF"
                      title={t('learning:form_academy_deadline_label')}
                      count={Infinity}
                      formatter={jumbotronData.deadlineFormatter}
                      description={jumbotronData.deadlineDescription}
                    />
                  </OverviewContent.Row>
                ) :
                (
                  <Spinner size="large" centered />
                )
            }
          </OverviewContent>
        </Overview>
        <Card>
          <OnboardingTable
            userId={userId}
            organisationId={orgId}
            setCourses={setCourses}
          />
        </Card>
      </Container.Column>
    </Container.Content>
  );
});

export default UserProfileOnboarding;
