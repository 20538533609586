import React from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmButton from '@common/components/confirm-button';
import { useThunkDispatch } from '@common/hooks';
import { AlertService } from '@services/alert';
import { deleteDocument, removeDocumentPermanently } from '../../actions';

import { resolveFolderKey } from '../../utils';
import { Filters } from '../../constants';
import type { Document } from '../../types';

type DeleteFolderModalProps = {
  documentItem: Document;
  onClose?: () => void;
  isPermanent: boolean;
  userId?: string;
};

const useTranslations = (documentItem: Document, isPermanent: boolean) => {
  const { t } = useTranslation();

  const translationContext = documentItem.is_folder ? 'folder' : 'file';

  const title = (() => {
    const translationStr = isPermanent
      ? 'documents:remove_permanently'
      : 'documents:remove';

    return t(translationStr, { context: translationContext, name: documentItem.name });
  })();

  const description = (() => {
    const translationStr = isPermanent
      ? 'documents:assert_user_wants_to_delete_permanently'
      : 'documents:assert_user_wants_to_delete';

    return t(translationStr, { context: translationContext });
  })();

  return {
    translationContext,
    title,
    description,
  };
};

export const DeleteDocumentModal = ({ onClose, documentItem, isPermanent, userId }: DeleteFolderModalProps) => {
  const thunkDispatch = useThunkDispatch();
  const { t } = useTranslation();
  const {
    translationContext,
    title,
    description,
  } = useTranslations(documentItem, isPermanent);

  const folderKey = resolveFolderKey({
    folderId: documentItem.parent_folder_id || undefined,
    filter: Filters.TRASH,
  }, userId);

  const handleRemove = async () => {
    try {
      if (isPermanent || documentItem.personal_root_user_id) {
        await thunkDispatch(removeDocumentPermanently(documentItem.id, folderKey, documentItem.personal_root_user_id));
      } else {
        await thunkDispatch(deleteDocument(documentItem.id, userId));
      }

      AlertService.success(t('documents:removed_successfully', { context: translationContext }));
    } catch (e: any) {
      if (e && typeof e === 'object' && 'status_code' in e) {
        AlertService.forStatus(e.status_code, {
          warning: t('documents:warning_removing_failed', { context: translationContext }),
          error: t('documents:error_removing_failed', { context: translationContext }),
        });
      }
    }

    onClose?.();
  };

  return (
    <ConfirmButton
      danger
      title={title}
      description={description}
      confirmText={t('common:delete')}
      onConfirm={handleRemove}
      onCancel={onClose}
    />
  );
};
