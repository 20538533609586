import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from '@common/components/button';
import { uploadFiles } from '../../../core/actions';
import { addFile, replaceFile } from '../../actions';

import type { Attachment } from '@common/types/objects';

type Props = {
  children?: React.ReactElement;
  parentFolderId: string | undefined;
  documentId?: string;
  userId?: string;
};

export const FileUploadForm = ({ children, parentFolderId, documentId, userId }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const onSelect = (e: { target: HTMLInputElement }) => {
    if (!e || !e.target.files) return;

    const files = Array.from(e.target.files);

    dispatch(uploadFiles(files, (attachment: Attachment) => (documentId
      ? dispatch(replaceFile(documentId, attachment))
      : dispatch(addFile(
        (parentFolderId !== 'personal' && parentFolderId !== undefined) ? parentFolderId : undefined,
        attachment,
        userId,
      ))
    ), true));
  };

  const handleOpenUpload = () => {
    if (!inputRef.current) return;
    inputRef.current.value = '';
    inputRef.current?.click();
  };

  const trigger = children ? React.cloneElement(React.Children.only(children), {
    onClick: handleOpenUpload,
  }) : (
    <Button
      type="primary"
      size="large"
      icon="upload"
      onClick={handleOpenUpload}
    >
      {t('documents:upload')}
    </Button>
  );

  return (
    <>
      {trigger}
      <input
        multiple={!documentId}
        ref={inputRef}
        type="file"
        onChange={onSelect}
        className="FileUpload__Input"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </>
  );
};
