import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FileViewer, FileViewerContent } from '@common/components/file-viewer';
import { Button } from '@common/components/button';

import type { Document, File } from '../../types';

type DocumentsFileViewerProps = {
  documents: Document[];
  fileInPreview?: File;
  setFileInPreview: (newFile: string | undefined) => void;
  handleToggleDocumentFavoriteState: (item: File) => Promise<void>;
  onPageChange?: (item: FileViewerContent) => void;
  onShow?: (item: FileViewerContent, page: number) => void;
  onDownloadFile?: (item: FileViewerContent) => void;
};

export const DocumentsFileViewer = ({
  documents,
  fileInPreview,
  setFileInPreview,
  handleToggleDocumentFavoriteState,
  onDownloadFile,
  onPageChange,
  onShow,
}: DocumentsFileViewerProps) => {
  const { t } = useTranslation();

  const files = useMemo(() => documents.filter((item): item is File => !item.is_folder), [documents]);

  const fileViewerActionButtons = useCallback((currentContent: File) => currentContent && (
    <Button
      icon={currentContent.is_favorited ? 'star__filled' : 'star'}
      type="dark"
      size="large"
      onClick={() => handleToggleDocumentFavoriteState(currentContent)}
    >
      {t(currentContent.is_favorited ? 'documents:remove_from_favorites' : 'documents:add_to_favorites')}
    </Button>
  ), []);

  if (!fileInPreview) return null;

  return (
    <FileViewer
      isVisible
      content={files}
      setIsVisible={(isVisible) => !isVisible && setFileInPreview(undefined)}
      initialPage={files.findIndex(({ id }) => id === fileInPreview.id)}
      moreActionButtons={fileViewerActionButtons}
      onDownloadFile={onDownloadFile}
      onPageChange={onPageChange}
      onShow={onShow}
    />
  );
};
