import React from 'react';
import type { SubmissionStatus as Status } from '../../types';

type Props = {
  status: Status;
};

export const SubmissionStatus = ({ status }: Props) => status && (
  <div className="SubmissionStatus">
    <div className="SubmissionStatus__Dot" style={{ backgroundColor: status.color }} />
    {status.name}
  </div>
);
