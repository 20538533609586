import React from 'react';
import { Switch as RRSwitch, Route } from 'react-router';
import RouteNotFoundComponent from '../route-not-found';

export const Switch = ({ children }: { children: React.ReactNode }) => (
  <RRSwitch>
    {children}
    <Route component={RouteNotFoundComponent} />
  </RRSwitch>
);
